<template>
  <cui-acl  :roles="['underDevelopment']" redirect>
    <h4>Lønnskjøringer</h4>
    <payrollsList />
    <a-divider />
    <newPayroll />
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import newPayroll from '@/components/custom/payroll/newPayroll'
import payrollsList from '@/components/custom/payroll/payrollsList'

export default {
  components: {
    cuiAcl,
    newPayroll,
    payrollsList,
  },
  data() {
    return {
      newP: false,
    }
  },
}
</script>

<style>

</style>
