<template>
  <div>
    <h4>
    Ny Lønnsutregning
    </h4>
    <a-spin :spinning="loading">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!-- <div class="col-md-3">
              <a-form-item label="Type" required>
                <a-select :options="importTypes" style="width: 100%" @change="getData()" v-model="filter.type" />
              </a-form-item>
            </div> -->
            <div class="col-md-3">
              <a-form-item label="Prosjekt" required>
                <a-select :options="projects" style="width: 100%" v-model="payroll.projects" mode="multiple"/>
              </a-form-item>
            </div>
            <div class="col-md-2">
              <a-form-item label="Periode" required>
                <a-month-picker v-model="payroll.period"/>
              </a-form-item>
            </div>
            <div class="col-md-12">
              <a-form-item label="Kommentar" required>
                <a-textarea placeholder="Notater" :rows="4" v-model="payroll.comment" />
              </a-form-item>
            </div>
            <div class="col-md-12">
              <a-button type="primary"  class="mt-3" @click="generatePayroll">Opprett Lønnskjøring</a-button>
            </div>
            <!-- <div class="col-md-12">
              <a-form-item label="Data" required>
                <a-textarea placeholder="Notater" :rows="4" v-model="input" @change="convertInput" />
              </a-form-item>
            </div> -->
          </div>
        </div>
      </div>
    </a-spin>
    <!-- <a-pagination v-model="currentPage" :total="data.length" show-less-items class="mt-3" style="width: 100%; text-align: center" /> -->
  </div>
</template>

<script>
import { openNotification } from '@/services/powerAutomate'
// import { calculateUsers, changeTransactionsStatus } from '@/services/common/payroll'
import { changeTransactionsStatus } from '@/services/common/payroll'

import { firestore } from '@/services/firebase'
import moment from 'moment'

const ref = firestore.collection('payrollTransactions')

export default {
  data() {
    return {
      projects: [],
      loading: false,
      wageTypeNumberCache: {},
      payroll: {
        company: 'Skycall',
        transactions: [],
        payrollImportDocRefs: [],
        payLines: [],
        users: [],
        projects: [],
        comment: '',
        period: moment().format('YYYYMM'),
      },
    }
  },
  mounted() {
    firestore.collection('settings').doc('projects').collection('projects')
      .where('company', '==', 'Skycall')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const project = {
            value: doc.id,
            text: doc.data().name,
            label: doc.data().name,
          }
          this.projects.push(project)
        })
      })
  },
  methods: {
    async getData() {
      const { payroll } = this
      const transactions = []
      const period = String(moment(this.period).format('YYYYMM'))
      if (payroll.projects.length) {
        const querySnapshot = await ref
          .where('period', '<=', period)
          .where('company', '==', payroll.company)
          .where('project', 'in', payroll.projects || [])
          .where('status', '==', 'unpaid')
          .get()

        querySnapshot.forEach((doc) => {
          const transaction = doc.data()
          transaction.id = doc.id
          this.payroll.transactions.push(doc.id)
          transactions.push(transaction)

          if (transaction.payrollImportDocRef && !this.payroll.payrollImportDocRefs.includes(transaction.payrollImportDocRef)) {
            this.payroll.payrollImportDocRefs.push(transaction.payrollImportDocRef)
          }
        })
        const summary = (await transactions.reduce(async (accumulatorPromise, transaction) => {
          const accumulator = await accumulatorPromise
          const key = `${transaction.period}-${transaction.employeeID}-${transaction.type}-${transaction.project}-${transaction.department}`

          if (!accumulator[key]) {
            const Year = String(moment(transaction.period).format('YYYY'))
            const Month = String(moment(transaction.period).format('MM'))
            accumulator[key] = {
              Year,
              Month,
              EmployeeNumber: transaction.employeeID,
              WageTypeNumber: await this.getWageTypeNumber(transaction.type),
              Comment: '',
              Count: 0,
              Amount: 0,
              ProjectNumber: transaction.project,
              DepartmentNumber: transaction.department,
              TransactionIDs: [],
              blocked: true,
            }
          }
          accumulator[key].Count = 1
          // round amount to 2 decimals
          accumulator[key].Amount += Math.round(Number(transaction.amount) * 100) / 100

          accumulator[key].TransactionIDs.push(transaction.id)

          return accumulator
        }, {}))
        this.payroll.payLines = Object.values(summary)
        // this.payroll.users = await calculateUsers(this.payroll.payLines, this.payroll.transactions)
      }
    },
    async getWageTypeNumber(type) {
      console.log(type)
      if (this.wageTypeNumberCache[type]) {
        // If the WageTypeNumber is in the cache, return it
        return this.wageTypeNumberCache[type]
      } else {
        // Fetch the WageTypeNumber from Firestore
        const querySnapshot = await firestore
          .collection('settings')
          .doc('payroll')
          .collection('WageTypeNumber')
          .where('type', '==', type)
          .get()

        if (!querySnapshot.empty) {
          const wageTypeNumberData = querySnapshot.docs[0].data()
          const wageTypeNumber = wageTypeNumberData.TripletexNumber

          // Store the fetched WageTypeNumber in the cache
          this.$set(this.wageTypeNumberCache, type, wageTypeNumber)

          return wageTypeNumber
        } else {
          console.error(`WageTypeNumber not found for type: ${type}`)
          return null
        }
      }
    },
    async blockPayrollImports(payrollImportIds) {
      const payrollImportsRef = firestore.collection('payrollImports')
      const batch = firestore.batch()

      for (const payrollImportId of payrollImportIds) {
        const payrollImportRef = payrollImportsRef.doc(payrollImportId)
        batch.update(payrollImportRef, { status: 'blocked' })
      }

      await batch.commit()
    },
    async generatePayroll() {
      // create a string period which takes year and month and adds a 0 in front if it's a single digit
      // e.g. year = 2021 and month = 1 becomes 202101
      // e.g. year = 2021 and month = 10 becomes 202110
      const period = String(moment(this.period).format('YYYYMM'))
      let title = `${this.payroll.projects.join(' ')} - ${period}`
      // check if payroll with same period and title already exists
      const querySnapshot = await firestore
        .collection('payrolls')
        .where('title', '==', title)
        .get()
      if (!querySnapshot.empty) {
        title = title.concat(` (${querySnapshot.size + 1})`)
        return
      }

      console.log(title, this.payroll, this.$store.state.user.employeeID, this.$store.state.user.name)
      this.loading = true
      await this.getData()
      firestore.collection('payrolls').add({
        ...this.payroll,
        period,
        status: 'underCalculation',
        title,
        generatedBy: this.$store.state.user.employeeID,
        generatedByName: this.$store.state.user.name,
        dateCreated: String(moment().format('YYYY-MM-DDTHH:mm')),
      }).then(async (docRef) => {
        await changeTransactionsStatus('underPayroll', this.payroll.transactions)
        await this.blockPayrollImports(this.payroll.payrollImportDocRefs)
        return docRef
      }).then(docRef => {
        this.$router.push({ path: `/payroll/calculation/${docRef.id}` })
      }).catch(err => {
        console.log(err)
        openNotification('Feil ved generering av Lønnskjøring, vennligst prøv igjen')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
