<template>
  <div>
    <!-- Display imports -->
    <a-spin :spinning="loading">
      <a-table bordered :data-source="dataSource" :columns="columns">
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" @click="$router.push({ path: `/payroll/calculation/${record.id}` })">{{ text }}</a>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
// import { openNotification } from '@/services/powerAutomate'
import moment from 'moment'
import dash from 'lodash'

export default {
  data() {
    return {
      newImport: false,
      input: '',
      dataSource: [],
      projects: [],
      loading: false,
      months: [],
      years: [],
      columns: [
        {
          title: 'Navn',
          dataIndex: 'title',
          scopedSlots: { customRender: 'operation' },
        },
        {
          title: 'Dato',
          dataIndex: 'dateCreated',
          customRender: (text) => moment(text).format('DD.MM.YYYY'),
        },
        {
          title: 'Generert Av',
          dataIndex: 'generatedByName',
        },
        {
          title: 'Periode',
          dataIndex: 'period',
        },
        {
          title: 'Status',
          dataIndex: 'status',
        },
        {
          title: 'Kommentar',
          dataIndex: 'comment',
        },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    moment,
    generateMonths() {
      moment.locale()
      this.months = Array.from({ length: 12 }, (_, i) => ({
        value: String(i + 1),
        label: String(dash.capitalize(moment.months(i))),
      }))
      this.years = Array.from({ length: 12 }, (_, i) => ({
        value: String(i + 2022),
        label: String(i + 2022),
      }))
    },
    getData() {
      const { filter } = this
      console.log(filter)
      firestore.collection('payrolls')
        // .where('year', '==', filter.year)
        // .where('month', '==', filter.month)
        // .where('type', '==', filter.type || '')
        // .where('project', '==', filter.project || '')
        .orderBy('dateCreated', 'asc')
        .get().then(snapshot => {
          this.dataSource = []
          this.count = 0
          snapshot.forEach(doc => {
            const data = doc.data()
            data.id = doc.id
            this.dataSource.push(data)
            this.count += 1
          })
          console.log(this.dataSource)
        })
    },
  },
}
</script>

<style>

</style>
